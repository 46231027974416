<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'receiptSlip' || modalId == 'afterCreateSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content bg-white border0">
          <!-- <div class="ribbon-wrapper" v-if="(details.status == 1)">
                <div class="ribbon bg-danger">Canceled</div>
          </div>-->
          <div class="cancel-watermark" v-if="details.status == 1">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-md-6">
                <h5
                  class="modal-title"
                  id="exampleModalCenterTitle"
                >Expense Slip - #{{(details.expense_id)}}</h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                 <div class="bill-head-icon mr-3">                   
                  <!-- <a :href="details.document" target="_blank" rel="noopener noreferrer" style="color:white;"><i class="fa fa-receipt"></i></a> -->
                  <i class="fa fa-receipt" title="View Bill" @click="viewBill()"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" title="Print" @click="print()"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  title="Close"
                  @click="$store.dispatch('modalClose')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
              <p class="mb-0 font-print-xxs">
                {{details.branch.address}}
                <br />
                Tel No:{{details.branch.phone}} / {{details.branch.email}}
                <br />
                PAN:{{authData.org_detail.pan_vat}}
              </p>


              <hr class="print-hr-margin" />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-4 col-lg-4 mg-t-10 mg-sm-t-0 order-2 order-sm-0">
                <label class="content-label mb-1">Account Detail</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <!-- <p class="mg-b-0 font-print-xxs">ACCOUNT ID : #{{(details.account.id)}}</p> -->
                <p
                  class="mg-b-0 font-print-xxs"
                >Account Type : {{details.account_type.title}} </p>
                <p
                  class="mg-b-0 font-print-xxs"
                >Account Name :{{details.account.account_name}}</p>
                
                <span v-if="details.supplier_id">
                  <label class="content-label mb-1 mg-t-10">Supplier Detail</label>
                <p class="mg-b-0 font-print-xxs" >
                  Name : {{details.supplier.name}}
                </p>
                <p class="mg-b-0 font-print-xxs">
                  Address: {{details.supplier.address}}
                </p>
                <p class="mg-b-0 font-print-xxs" >
                  PAN : {{details.supplier.pan_vat_no}}
                </p>
               </span>
              </div>
              <!-- Col -->
              <div class="col-sm-4 col-lg-4">
                <p class="text-center font-l m-0">Expense Slip</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 mg-t-sm-15 mg-t-10 order-1 order-sm-0">
                <p class="mg-b-0 text-sm-right font-print-xxs">
                  Expense Id : #{{(details.expense_id)}} -
                  <span class="tx-primary">{{details.import==0?'LOCAL':'IMPORT'}}</span>
                </p>
                <p class="mg-b-0 text-sm-right font-print-xxs">Date : {{details.date}}</p>
                 <p class="mg-b-0 text-sm-right font-print-xxs" v-if="details.chalan_no">
                  Chalan No. : {{(details.chalan_no)}}
                </p>
                <!-- <p class="mg-b-0 text-sm-right tx-danger" v-if="details.status==1"> CANCEL DATE : {{details.cancel_date}}</p> -->
              </div>
              <!-- col -->
            </div>
            <hr />
            <!-- row -->
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-7 col-lg-7 print-sm-12 print-lg-4 order-2 order-sm-0 mg-t-5 mg-sm-t-0"
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-0 mb-sm-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p
                    class="mg-b-0 font-print-xxs"
                  >{{toWord((details.expense_amount + details.vat_amount))}} only/-</p>
                </div>
                <div class="table-responsive mt-2">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Ref.</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{details.payment_method.title}}
                          <span v-if="details.bank_id">-{{details.bank.bank}}</span>
                        </td>
                        <td>{{details.transaction_id}}</td>
                        <td>{{details.paid_amount}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
              <div
                class="col-sm-4 col-lg-4 print-sm-12 print-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0 font-print-xxs"
              >
                <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                  <li class="d-flex justify-content-between">
                    <span>Expense Amount</span>
                    <span>{{parseDigitForSlip(details.expense_amount)}}</span>
                  </li>
                  <li class="d-flex justify-content-between" v-if="details.vat_bill == 1">
                    <span>VAT able Amount</span>
                    <span>{{parseDigitForSlip(details.vatable_amount)}}</span>
                  </li>
                  <li class="d-flex justify-content-between" v-if="details.vat_bill == 1">
                    <span>VAT Amount (13%)</span>
                    <span>{{parseDigitForSlip(details.vat_amount)}}</span>
                  </li>
                  <li class="d-flex justify-content-between" v-if="details.vat_bill == 1">
                    <span>Total</span>
                    <span>{{parseDigitForSlip(details.bill_amount)}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Paid</span>
                    <span>{{parseDigitForSlip(details.paid_amount)}}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Due</span>
                    <span>{{parseDigitForSlip(details.due_amount)}}</span>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <!-- Footer Container -->
            <div class="print-mb-3">
              <div class="row">
                <div class="col-sm-9">
                  <div class="footer_note" v-if="details.description">
                    <label class="content-label mb-1 no-print-sm">Note</label>
                    <p class="mb-0 text-center text-sm-left font-print-xxs">{{details.description}}</p>
                  </div>
                </div>
                <div class="col-sm-3">
                  <p class="mb-0 mt-p-2 text-right font-print-xxs">
                    <span>User: {{details.user.name}}</span>
                  </p>
                </div>
              </div>
              <div v-if="details.status == 1">
                <hr />
                <div class="footer_note">
                  <label class="content-label mb-1 tx-danger">Cancellation Detail</label>
                  <p class="mg-b-0 font-print-xxs">Cancellation Date : {{details.cancel_date}}</p>
                  <p
                    class="mg-b-0 font-print-xxs"
                    v-if="details.cancel_user_id"
                  >Cancellation User : {{details.user.name}}</p>
                  <p class="mb-2 text-sm-left font-print-xxs">Remark: {{details.remarks}}</p>
                </div>
                <hr class="print-display-only-block" />
              </div>
            </div>
            <!-- <div class="footer_note" v-if="details.description">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{details.description}}
              </p>
            </div>
             <div class="footer_note" v-if="details.remarks">
              <label class="content-label mb-1">Remarks</label>
              <p class="mb-2">
                {{details.remarks}}
              </p>
            </div>
            <p class="text-right">User: {{details.user.name}}</p>
            <p class="text-right tx-danger" v-if="details.cancel_user_id">Cancel User: {{details.user.name}}</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists8",
      "authData",
      "modalId",
      "modalState",
      "dataId",
    ]),
    ...mapGetters("expenses", ["expenseLists", "expenseElements"]),
  },
  methods: {
    print() {
      window.print();
    },
    viewBill(){
       let mimeType = this.details.document.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
       var newTab = window.open();
       if(mimeType == "application/pdf"){
         newTab.document.body.innerHTML = '<embed width="100%" height="100%" src="'+this.details.document+'" type="application/pdf" />';
         }else{
           newTab.document.body.innerHTML = '<img src="'+this.details.document+'" >';
         }
    },
  },
  watch: {
    modalId(value) {
      if (value == "receiptSlip") {
        let id = this.dataId;
        this.details = this.expenseLists.find(function (data) {
          return data.id == id;
        });
      }
      if (value == "afterCreateSlip") {
        let id = this.dataId;
        this.details = this.expenseLists.find(function (data) {
          return data.id == id;
        });
      }
    },
  },
};
</script>