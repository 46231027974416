<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Expense
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Expense</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_expense')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newExpense',
              mode: 'create',
            })
          "
        >
          New Expense
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-10 d-block mr-0 pr-0" @change="searchFilter">
          <select v-model="params.pageIndex" id="expense_filter_size" class="filter-input mr-2 mg-t-5">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select v-model="params.branch" class="filter-input mr-2 mg-t-5" id="expense_filter_branch_type" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="branch in expenseElements.filter_branchs"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select v-model="params.type" class="filter-input mr-2 mg-t-5" id="expense_filter_expense_type" @change="searchFilter">
            <option value="">All Expense Type</option>
            <option
              v-for="(type,index) in expenseElements.account_types"
              :key="index"
              :value="type.id"
            >
              {{ type.title }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="params.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              id="expense_filter_date_from"
              @change="searchFilter"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="params.to_date"
              placeholder="To"
              class="ml-1 filter-input mr-1 mg-t-5"
              id="expense_filter_date_to"
              @change="searchFilter"
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            id="expense_filter_search"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Expense Id</th>
              <th class="wd-15p">Account Name</th>
              <th class="wd-10p">Payment Mode</th>
              <th class="wd-10p">Expense Amount</th>
              <th class="wd-10p">Bill Amount</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Due Amt.</th>
              <th class="wd-10p text-center table-end-item" v-if="checkIfAnyPermission(['view_expense','cancel_expense','edit_expense'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && expenseLists.length > 0">
            <tr v-for="(expense, index) in expenseLists" :key="index">
              <th scope="row" class="table-start-account">{{ ++index }}.</th>
              <td>{{ expense.date }}</td>
              <td>#{{ expense.expense_id }} <span v-if="expense.status == 1" class="tx-danger">-Canceled</span></td>
              <td>{{ expense.account.account_name }}</td>
              <td>
                {{ expense.payment_method.title }}
                <span v-if="expense.bank_id">-{{ expense.bank.bank }}</span>
              </td>
              <td>{{parseDigitForSlip(expense.expense_amount)}}</td>
              <td>{{ parseDigitForSlip(expense.bill_amount) }}</td>
              <td>{{ parseDigitForSlip(expense.paid_amount) }}</td>
              <td>{{ parseDigitForSlip(expense.due_amount )}}</td>
              <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_expense','cancel_expense','edit_expense'])">
                <a href="javascript:;" class="mr-3" :id="'expenseView'+expense.id">
                  <i
                  title="View"
                  v-if="checkSinglePermission('view_expense')"
                    class="fas fa-eye tx-success"
                    @click="
                      $store.commit('setDataId', expense.id),
                        $store.dispatch('modalWithMode', {
                          id: 'receiptSlip',
                          mode: 'view',
                        })
                    "
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  :id="'expenseEdit'+expense.id"
                  title="Edit"
                  @click="edit('editExpense', expense.id)"
                  v-if="expense.status == 0 && checkSinglePermission('edit_expense')"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  :id="'expenseCancel'+expense.id"
                  title="Cancel"
                  @click="cancel(expense.id)"
                  v-if="expense.status == 0 && checkSinglePermission('cancel_expense')"
                >
                  <i class="fas fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td colspan="11" class="text-center">
              {{error_message}}
            </td>
          </tbody>
          <tbody v-else-if="!loading && expenseLists.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
          
        </table>
        <Paginate 
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
          />
        <ExpenseCreate @parent-event="getData()" />
        <ExpenseEdit @parent-event="getData()" />
        <ReceiptSlip />
      </div>
    </div>
  </div>
</template>
<script>
import ExpenseCreate from "./create";
import ExpenseEdit from "./edit";
import ReceiptSlip from "./Slip";
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
import { mapGetters } from "vuex";
import Paginate from 'vuejs-paginate';
import Services from "./Services/Services";
import _ from 'lodash';
export default {
  components: {
    ExpenseCreate,
    ExpenseEdit,
    ReceiptSlip,
    SearchDayMonthComponent,
    Paginate,
  },
  data() {
    return {
      params: {
        pageIndex: 10,
        searched: "",
        offset: 0,
        branch: "",
        type: "",
        from_date: "",
        to_date: '',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      totalPageCount: 0,
      pageSerialNo: 1,
      loading: true,
      error: false,
      error_message:'',
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("expenses", ["expenseLists", "expenseElements"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    searchKeyword:_.debounce(function(){
          this.params.offset = 0;
          this.page=1;
          this.pageSerialNo = 1;
        this.getFilteredDataWithoutDates();
    },1000),
    searchFilter(){
      this.params.offset = 0;
      this.page=1;
      this.pageSerialNo = 1;
      this.getFilteredDataWithoutDates();
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Expense ?",
        html: `<label>Remarks: </label>
                <textarea id="cancel-remarks" class="swal2-input"></textarea>
            `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
         return Services.cancelExpense(id, { remarks: remarks })
            .then((response) => {
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
                this.getData();
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from_date: this.params.from_date,
        to_date: this.params.to_date,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getExpenseList(params)
        .then((res) => {
          this.$store.commit("expenses/setExpenseLists", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
         if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.$store.commit("expenses/setExpenseLists", []);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.getFilteredDataWithoutDates();
      this.getElements();
    },
    getElements() {
      Services.getExpenseElements()
        .then((res) => {
          this.$store.commit("expenses/setExpenseElements", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>