<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newExpense'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">Add New Expense</h5>
            <a class="close" title="Close" @click="$store.dispatch('modalClose', 'addNewItem')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Expense Type</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="expense_type"
                                v-model="formData.type_id"
                                id="direct_expense"
                                :value="expenseElements.direct_expense_id"
                                @change="accountHeads()"
                                required
                              />
                              <label for="direct_expense" class="ml-2">Direct Expense</label>
                            </div>
                          </div>
                          
                          <div class="col-md-6">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="expense_type"
                                v-model="formData.type_id"
                                id="indirect_expense"
                                :value="expenseElements.indirect_expense_id"
                                @change="accountHeads()"
                                required
                              />
                              <label for="indirect_expense" class="ml-2">Indirect Expense</label>
                            </div>
                              
                          </div>
                          
                        </div>
                        <div class="col-12">
                          
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.branch_id"
                              class="form-control"
                              id="expense_branch_name"
                              :disabled="branchDisabled"
                              @change="getExpenseIdByBranch()"
                              required
                            >
                              <option value disabled>Select Branch</option>
                              <option
                                v-for="(
                                  branch, index
                                ) in expenseElements.branches"
                                :key="index"
                                :value="branch.id"
                              >{{ branch.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Expense ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.expense_id"
                              class="form-control"
                              id="expense_id"
                              disabled
                              placeholder="id"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="date"
                              v-model="formData.date"
                              class="form-control"
                              id="expense_date"
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Account Head *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              id="expense_account_head"
                              v-model="formData.account_id"
                              required
                              title="Please Select Expense Type First"
                            >
                              <option value disabled>Account Head</option>
                              <option
                                v-for="(account, index) in account_heads"
                                :key="index"
                                :value="account.id"
                              >{{ account.account_name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Supplier</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <select
                              class="form-control"
                              id="expense_supplier"
                              v-model="formData.supplier_id"
                              @change="supplierChange()"
                            >
                              <option value>Select Supplier</option>
                              <option
                                v-for="(
                                  supplier, index
                                ) in expenseElements.suppliers"
                                :key="index"
                                :value="supplier.id"
                              >{{ supplier.name }}</option>
                            </select>
                          </div>
                        </div>
                         <div class="row">
                          <div class="col-md-5 mr-0 pr-0 mt-0">
                            <label class="btn btn-primary ht-20 p-1" for="file">Browse</label>
                          </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              id="file"
                              name="file"
                              style="display: none"
                              type="file"
                              @change="base64Image"
                            />
                            <input
                              class="form-control"
                              id="asset_image"
                              placeholder="Upload PDF / Image"
                              readonly
                              style="background: #f4f7fa"
                              v-model="formData.document"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.payment_method_id"
                              class="form-control"
                              id="expense_payment_method"
                              @change="getBanks()"
                              required
                            >
                              <option value disabled>Select Payment Method</option>
                              <option
                                v-for="(method, index) in payement_methods"
                                :key="index"
                                :value="method.id"
                              >{{ method.title }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.bank_id"
                              class="form-control"
                              id="expense_bank_name"
                              :disabled="bank_disabled"
                              required
                            >
                              <option value disabled>Select Bank</option>
                              <option
                                v-for="(bank, index) in banks"
                                :key="index"
                                :value="bank.id"
                              >{{ bank.bank }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="input"
                              v-model="formData.transaction_id"
                              placeholder="Payment Ref."
                              class="form-control"
                              id="expense_transaction_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Settings</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            v-model="formData.import"
                            id="expense_import"
                          />
                          <label for="expense_import" class="ml-2">Import</label>
                        </div>
                        <div class="group-note">
                          <span>Note:</span> Please Check the above option if
                          expense is imported
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="from-group-check">
                          <input
                            class="group-check-box"
                            type="checkbox"
                            name="expense_type"
                            id="vat_bill"
                            v-model="formData.vat_bill"
                            value="0"
                            @change="vatBillChange()"
                            @keyup="vatBillChange()"
                          />
                          <label for="vat_bill" class="ml-2">VAT Bill</label>
                        </div>
                        <div class="group-note pd-b-7">
                          <span>Note:</span> Please Check the above option for
                          VAT billed expense
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Chalan No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              id="expense_chalan_no"
                              placeholder="Chalan No."
                              v-model="formData.chalan_no"
                              :required="chalan_required"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Expense Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              min="1"
                              step="any"
                              v-model="formData.expense_amount"
                              class="form-control"
                              id="expense_amount"
                              placeholder="0.00"
                              required
                              @change="calculateBill()"
                              @keyup="calculateBill()"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">VAT-able Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              :max="formData.expense_amount"
                              v-model="formData.vatable_amount"
                              class="form-control"
                              id="expense_vatable_amount"
                              placeholder="0.00"
                              min="0"
                              :required="vatable_amount_required"
                              :disabled="vatable_amount_disable"
                              @change="calculateCustomVatableAmount()"
                              @keyup="calculateCustomVatableAmount()"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">VAT Amount</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.vat_amount"
                              class="form-control"
                              id="expense_vat_amount"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bill Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.bill_amount"
                              class="form-control"
                              id="expense_bill_amount"
                              placeholder="0.00"
                              disabled
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Paid Amount *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              v-model="formData.paid_amount"
                              class="form-control"
                              id="expense_paid_amount"
                              :max="formData.bill_amount"
                              placeholder="0.00"
                              required
                              :disabled="paid_amount_disabled"
                              @change="calculateDue()"
                              @keyup="calculateDue()"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Remaining Amt.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.due_amount"
                              class="form-control"
                              id="expense_remaining_amount"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="expense_description"
                              v-model="formData.description"
                              style="height: 4.65rem"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >{{ disableSubmitButton ? "Submitting..." : "Submit" }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ReceiptSlip />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import ReceiptSlip from "./Slip";
export default {
  components: {
    ReceiptSlip,
  },
  data() {
    return {
      account_heads: [],
      formData: {
        id: "",
        type_id: "",
        branch_id: "",
        expense_id: null,
        date: null,
        account_id: "",
        chalan_no:"",
        payment_method_id: "",
        bank_id: "",
        transaction_id: null,
        supplier_id: "",
        import: 0,
        vat_bill: 0,
        document:null,
        expense_amount: 0,
        vatable_amount: 0,
        vat_amount: 0,
        bill_amount: 0,
        paid_amount: 0,
        due_amount: 0,
        description: null,
      },
      chalan_required: false,
      vatable_amount_disable: true,
      vatable_amount_required: false,
      bank_disabled: true,
      payement_methods: [],
      banks: [],
      paid_amount_disabled: true,
      disableSubmitButton: false,
      branchDisabled:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
      "today",
    ]),
    ...mapGetters("expenses", ["expenseLists", "expenseElements"]),
  },
  mounted() {
    this.formData.date = this.today;
  },
  methods: {
    submit() {
      this.disableSubmitButton = true;
      Services.createNewExpense(this.formData)
        .then((res) => {
          this.$store.commit("expenses/setExpenseLists", res.data.data.data);
          this.$store.commit("setDataId", res.data.data.id);
          this.$store.dispatch("modalWithMode", {
            id: "afterCreateSlip",
            mode: "read",
          });
          this.$emit("parent-event");
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let error = Object.values(err.response.data.errors).flat();
            this.setNotification(error);
          } else {
            let error = err.response.data.error_message;
            this.setNotification(error);
          }
        })
        .finally(() => {
          this.disableSubmitButton = false;
        });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    accountHeads() {
      this.formData.account_id = "";
      if (this.expenseElements.accounts.length != 0) {
        let account_type = this.formData.type_id;
        let details = this.expenseElements.accounts.filter(function (data) {
          return data.account_type == account_type;
        });
        this.account_heads = { ...details };
      }
    },
     base64Image(e) {
      var fileExt = e.target.files[0]["name"].split('.').pop();
      if(fileExt=="jpg"||fileExt=="png"||fileExt=="pdf"||fileExt=="jpeg"){
        this.formData.file = e.target.files[0]["name"];
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.formData.document = e.target.result;
        };
          console.log( this.formData.document)
      }else{
        alert("File type not supported.");
      }
      
    },
    getExpenseIdByBranch() {
      this.getPayments();
      Services.getExpenseIdByBranch(this.formData.branch_id)
        .then((res) => {
          this.formData.expense_id = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPayments() {
      this.bank_disabled = true;
      this.formData.bank_id = "";
      if (this.expenseElements.payment_methods.length != 0) {
        let details = [];
        let methods = this.formData.branch_id;
        details = this.expenseElements.payment_methods.filter(function (data) {
          return data.branch == methods;
        });
        this.payement_methods = { ...details };
        this.formData.payment_method_id = this.payement_methods[0].id;
      }
    },
    getBanks() {
      let payment_bank = this.formData.payment_method_id;
      let values = this.expenseElements.payment_methods.find(function (data) {
        if (data.id == payment_bank) {
          return data.title;
        }
      });
      if (values.title.toLowerCase() == "bank") {
        if (this.expenseElements.banks.length != 0) {
          this.bank_disabled = false;
          let details = [];
          let methods = this.formData.branch_id;
          details = this.expenseElements.banks.filter(function (data) {
            return data.branch == methods;
          });
          this.banks = { ...details };
          if (details.length > 0) {
            this.formData.bank_id = details[0].id;
          } else {
            this.formData.bank_id = "";
          }
        } else {
          this.bank_disabled = false;
          this.formData.bank_id = "";
        }
      } else {
        this.bank_disabled = true;
        this.formData.bank_id = "";
      }
    },
    supplierChange() {
      if (this.formData.supplier_id != "") {
        this.paid_amount_disabled = false;
      } else {
        this.formData.paid_amount = this.formData.bill_amount;
        this.paid_amount_disabled = true;
      }
      this.calculateBill();
    },
    vatBillChange() {
      if (this.formData.vat_bill == 0) {
        this.chalan_required = false;
        this.vatable_amount_disable = true;
        this.vatable_amount_required = false;
        this.formData.vatable_amount = 0;
      } else {
        this.chalan_required = true;
        this.vatable_amount_disable = false;
        this.vatable_amount_required = true;
      }
      this.calculateBill();
    },
    calculateBill() {
      if (this.formData.vat_bill == 0) {
        this.formData.vatable_amount = 0;
        this.formData.vat_amount = 0;
        this.formData.bill_amount = this.parseDigit(this.formData.expense_amount);
        this.formData.paid_amount = this.parseDigit(this.formData.expense_amount);
        this.formData.due_amount =
          this.parseDigit(this.formData.bill_amount) -
          this.parseDigit(this.formData.paid_amount);
      }
      if (this.formData.vat_bill == 1) {
        this.formData.vatable_amount = this.parseDigit(this.formData.expense_amount);
        this.formData.vat_amount =
          this.parseDigit(this.formData.vatable_amount * 0.13);
        this.formData.bill_amount =
          this.parseDigit(this.formData.expense_amount) +
          this.parseDigit(this.formData.vat_amount);
        this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
        this.formData.due_amount =
          this.parseDigit(this.formData.bill_amount) -
          this.parseDigit(this.formData.paid_amount);
      }
       if(this.formData.expense_amount == ""){
        this.formData.vatable_amount = 0;
        this.formData.vat_amount = 0;
        this.formData.bill_amount = 0;
        this.formData.paid_amount = 0;
        this.formData.due_amount = 0;
      }
    },
    calculateCustomVatableAmount() {
      //  this.formData.vatable_amount = parseFloat(this.formData.expense_amount);
      
      this.formData.vat_amount =
        parseFloat(this.formData.vatable_amount) * 0.13;
      if(this.formData.vatable_amount == ""){
        this.formData.vat_amount = 0;
        this.formData.bill_amount = this.formData.expense_amount;
      }
      this.formData.bill_amount =
        this.parseDigit(parseFloat(this.formData.expense_amount) +
        parseFloat(this.formData.vat_amount));
      this.formData.paid_amount = this.parseDigit(this.formData.bill_amount);
      this.formData.due_amount =
        this.parseDigit(parseFloat(this.formData.bill_amount) -
        parseFloat(this.formData.paid_amount));
    },
    calculateDue() {
      if(this.formData.expense_amount != ""){
        this.formData.due_amount =
        this.parseDigit(parseFloat(this.formData.bill_amount) -
        parseFloat(this.formData.paid_amount));
      }
      if(this.formData.paid_amount ==""){
        this.formData.due_amount = this.parseDigit(this.formData.bill_amount);
      }
    },
     getElements() {
      Services.getExpenseElements()
        .then((res) => {
          this.$store.commit("expenses/setExpenseElements", res.data.data);
          if(res.data.data.branches.length == 1)
          {
            this.formData.branch_id = res.data.data.branches[0].id;
            this.branchDisabled = true;
            this.getExpenseIdByBranch();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearData() {
      (this.account_heads = []), (this.formData.id = "");
      this.formData.type_id = "";
      this.formData.branch_id = "";
      this.formData.expense_id = null;
      this.formData.date = this.today;
      this.formData.account_id = "";
      this.formData.payment_method_id = "";
      this.formData.bank_id = "";
      this.formData.transaction_id = null;
      this.formData.supplier_id = "";
      this.formData.import = 0;
      this.formData.vat_bill = 0;
      this.formData.expense_amount = 0;
      this.formData.chalan_no="";
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.paid_amount = 0;
      this.formData.due_amount = 0;
      this.formData.description = null;
      this.chalan_required = false;
      this.vatable_amount_disable = true;
      this.vatable_amount_required = false;
      this.bank_disabled = true;
      this.payement_methods = [];
      this.banks = [];
      this.paid_amount_disabled = true;
      this.disableSubmitButton = false;
      this.branchDisabled=false;
    },
  },
  watch: {
    modalMode(value) {
      if (value == "create") {
        this.clearData();
        this.getElements();
      }
    },
  },
};
</script>