import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class ExpenseService{
    getExpenseList(param){
        const url = `api/expenses/filter`
        const params = {
            'page-index': param.pageIndex,
            'branch': param.branch,
            'today': param.today,
            'month': param.month,
            'year': param.year,
            'from': param.from_date,
            'type':param.type,
            'to': param.to_date,
            'searched': param.searched,
            'offset': param.offset,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getExpenseElements(){
        const url = `api/expense/create`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createNewExpense(formdata){
        const url = `api/expense/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    cancelExpense(id,formdata){
        const url = `api/expenses/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    updateExpense(id,formdata){
        const url = `api/expenses/update/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    getExpenseIdByBranch(id){
        const url = `api/expenses/id/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }

}
export default new ExpenseService();